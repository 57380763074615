// config.js

const config = {
    development: {
        apiDocs: 'http://localhost:8000/docs',
        apiUrl: 'http://localhost:8000/api',
        wsUrl: 'ws://localhost:8000/ws',
        wssUrl: 'wss://localhost:8000/ws'
        // Add other development-specific settings here
    },
    production: {
        apiDocs: '/docs',
        apiUrl: '/api',
        wsUrl: 'ws://' + location.hostname + ':' + location.port.toString() + '/ws',
        wssUrl: 'wss://' + location.hostname + '/ws'
        // Add other production-specific settings here
    }
}

export default process.env.NODE_ENV === 'production' ? config.production : config.development
