import {ref} from 'vue';
import config from '@/config.js'
import {toast} from "vue3-toastify";
import "vue3-toastify/dist/index.css";

// Reactive state to store WebSocket messages
export const websocketData = ref(null); // Shared state for WebSocket data

export function initializeWebSocket() {
    let ws = null;
    let ws_connected = ref(false);
    let reconnectAttempts = 0; // Track the number of reconnection attempts

    // Exponential backoff factor to avoid too frequent reconnect attempts
    const reconnectDelay = (attempts) => Math.min(1000 * Math.pow(2, attempts), 10000); // Exponential backoff: 1s, 2s, 4s, ... 10s max


    function connect_ws() {
        let websocketURL = config.wsUrl;
        if (location.protocol === 'https:') {
            websocketURL = config.wssUrl;
        }

        // Ensure we don't create multiple connections if the socket is already open
        if (ws && ws.readyState === WebSocket.OPEN) {
            console.log("WebSocket is already open.");
            return;
        }

        ws = new WebSocket(websocketURL);

        ws.onopen = () => {
            reconnectAttempts = 0; // Reset reconnect attempts on successful connection
            console.log("WebSocket connection is now open.");
            ws_connected.value = true;
            ws.send('Connect');
        };

        ws.onmessage = (event) => {
            if (event.data === 'ping') {
                console.log('Received ws-ping')
                return
            }

            try {
                const data = JSON.parse(event.data);
                console.log('Received message:', data);
                if (data.hasOwnProperty('toast')) {
                    const htmlString = "<div><h4>" + data.toast.title + "</h4><p>" + data.toast.message + "</p></div>"
                    toast(htmlString, {
                        "theme": "dark",
                        "type": data.toast.type,
                        "autoClose": 2000,
                        "transition": "flip",
                        "dangerouslyHTMLString": true
                    })
                } else {
                    // Used for 'streaming' updates e.g. subtitle progress
                    websocketData.value = data;
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        }

        ws.onclose = (e) => {
            console.log(`Socket is closed. Reconnect will be attempted in ${reconnectDelay(reconnectAttempts) / 1000} seconds. Reason: ${e.reason}`);
            ws_connected.value = false;
            reconnectAttempts += 1;

            // Attempt to reconnect with exponential backoff
            setTimeout(() => {
                connect_ws();
            }, reconnectDelay(reconnectAttempts));
        }

        ws.onerror = (err) => {
            console.error('Socket encountered error: ', err.message);
        }
    }

    // Start the WebSocket connection
    connect_ws();

    return {
        connect_ws,
        ws,
        ws_connected
    };
}
